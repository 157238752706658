.top-link {
  text-decoration: none !important;
  transition: all .25s ease-in-out;
  position: fixed;
  bottom: 40px;
  right: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 40px;
  border: 3px solid #252525 !important;
  color: #333 !important;
  background-color: #fff !important;
  /* z-index chosen to be above navbars (which are sticky) and to be above the instant contact button on mobile.
  https://getbootstrap.com/docs/4.6/layout/overview/#z-index */
  z-index: 1026;
  opacity: 0.6;

  &.hide {
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }

  .back-to-top-icon {
    font-size: 21px;
  }
}
