
/*
* This is a manifest file that'll be compiled into application.css, which will include all the files
* listed below.
*
* Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
* vendor/assets/stylesheets directory can be referenced here using a relative path.
*
* You're free to add application-wide styles to this file and they'll appear at the bottom of the
* compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
* files in this directory. Styles in this file should be added after the last require_* statement.
* It is generally better to create a new file per style scope.
*
* require public_facing/show_breakpoint
*/

@import url('./unsubscribe_form.css');
@import url('./site_search_result.css');

@media all and (min-width: 992px) {
	/* Show drop-down menus on hover instead of needing to click */
	.navbar-expand-lg .nav-item:hover .dropdown-menu {
		display: block;
	}
	.navbar-expand-lg .nav-item .dropdown-menu {
		margin-top: 0;
	}
	/* Center the drop-down when the nav menu is justified, otherwise it sits too far to the left. */
	.navbar-expand-lg .nav-justified .dropdown-menu {
		left: 50%;
		transform: translateX(-50%);
	}
}

@media all and (min-width: 1200px) {
	/* Show drop-down menus on hover instead of needing to click */
	.navbar-expand-xl .nav-item:hover .dropdown-menu {
		display: block;
	}
	.navbar-expand-xl .nav-item .dropdown-menu {
		margin-top: 0;
	}
	/* Center the drop-down when the nav menu is justified, otherwise it sits too far to the left. */
	.navbar-expand-xl .nav-justified .dropdown-menu {
		left: 50%;
		transform: translateX(-50%);
	}
}

/* This is necessary to make textarea elements take up 100% of the height of their container. */
.form-item-value {
	display: grid;
}

.form-section {
	textarea {
		/* The 2px here is for the top and bottom border. */
		height: calc(100% - 2px);
	}

	input[type=text], input[type=email], input[type=number] {
		padding: 10px;
		min-height: 100%;
	}

	select {
		padding: 10px 6px;
		min-height: 100%;
	}

	input[type=submit] {
		margin-top: 6px;
	}
}

/* The React and Rails systems output modals a bit different, need to support both. */
.modal.no-frame, .modal .no-frame {
	.modal-footer {
		display: none;
	}

	.modal-body {
		padding: 0;
	}
}

.nlux-AiChat-root {
	font-family: inherit;
	--nlux-prmInp--ftFm: inherit;
	--nlux-chtr--ftSz: inherit;
}
