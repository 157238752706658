.site_search_result_wrapper {
  width: 100%;
  .site_search_result_container {
    width: 60%;
    margin: 0 auto;
    .default_body {
      .site_search_pagination_links > * {
        padding-right: 10px;
      }
    }
  }
}
