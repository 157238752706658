.unsubscribe_form {
	flex-direction: column;
	align-items: center;
	display: flex;
  label{
  	font-size: 15px;
  }
  button{
		border: none;
		box-shadow: 2px 2px 2px grey;
		padding: 4px;
		border-radius: 4px;
		margin-top: 10px;
		margin-left: auto;
		margin-right: auto;
		display: block;
  }
  .submit{
    width: 200px;
  }
}
.error_message{
	text-align: center;
	color: red;
	font-size: 25px;
}
.unsubscribe_response{
	text-align: center;
	font-size: 25px;
}
